<template>
  <div
    ref="root"
    v-click-outside="closeOnOutsideClick ? closeDropdown : () => {}"
    class="inline-block"
  >
    <slot
      name="activator"
      :attrs="attrs"
      :is-opened="isOpened"
      :open-dropdown="openDropdown"
      :close-dropdown="closeDropdown"
      :toggle-dropdown-visibility="toggleDropdownVisibility"
    />
    <template v-if="optionsDisplayType === DropdownOptionsDisplayType.INLINE">
      <div v-show="isOpened" role="menu">
        <slot :close-dropdown="closeDropdown" />
      </div>
    </template>
    <div
      v-else
      v-show="isOpened"
      :id="parsedId"
      ref="dropdown"
      role="menu"
      class="min-w-50 bg-surface rounded-2xl flex flex-col p-2 border z-50"
    >
      <slot :close-dropdown="closeDropdown" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import vClickOutside from 'click-outside-vue3/src/v-click-outside';
import { getUid } from '@/utils/common';
import { PopperOptions, usePopper } from '@/composables/use-popper';
import { DropdownOptionsDisplayType } from '~/components/dropdown/dropdown.constants';

type Props = {
  id?: string;
  optionsDisplayType?: DropdownOptionsDisplayType;
  openedByDefault?: boolean;
  closeOnOutsideClick?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  optionsDisplayType: DropdownOptionsDisplayType.POPPER,
  closeOnOutsideClick: true,
});

const parsedId = computed(() => {
  return props.id ?? `dropdown-${getUid()}`;
});

const dropdown = ref(null);
const root = ref(null);
const popperOptions: PopperOptions = {
  placement: 'bottom-end',
  openedByDefault: props.openedByDefault,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 2],
      },
    },
  ],
};
const { isOpened } = usePopper({
  reference: root,
  popper: dropdown,
  options: popperOptions,
});

const openDropdown = () => {
  isOpened.value = true;
};
const closeDropdown = () => {
  isOpened.value = false;
};
const toggleDropdownVisibility = () => {
  isOpened.value = !isOpened.value;
};

const attrs = computed(() => ({
  'aria-haspopup': 'true',
  'aria-expanded': isOpened.value.toString(),
  'aria-controls': parsedId.value,
}));
</script>
